var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-1 flex-col max-h-full overflow-hidden"},[_c('sub-header',{attrs:{"backButton":true}}),_c('div',{staticClass:"flex flex-1 flex-col flex-grow max-h-full max-w-full overflow-auto scroll-bar min-h-full overflow-y-auto"},[(_vm.loading)?_c('div',{staticClass:"flex items-center justify-center flex-1 bg-white py-16 rounded-md flex-col"},[_c('Loader',{staticClass:"my-5",attrs:{"size":8}}),_vm._v(" Loading")],1):_c('div',{staticClass:"bg-white rounded-xl shadow p-6 flex flex-col gap-5"},[_c('div',{staticClass:"flex justify-center items-center"},[_c('div',{staticClass:"flex gap-6 flex-col flex-1"},[_c('div',{staticClass:"flex flex-col gap-2"},[_c('span',{staticClass:"text-gray-500 text-md"},[_vm._v(" User Name ")]),_c('neo-input',{staticClass:"rounded-lg h-10 w-full lg:w-2/6",attrs:{"placeholder":"Group Name","bg":"white"},model:{value:(_vm.userDetails.user_name),callback:function ($$v) {_vm.$set(_vm.userDetails, "user_name", $$v)},expression:"userDetails.user_name"}})],1),_c('div',{staticClass:"flex flex-col gap-2"},[_c('span',{staticClass:"text-gray-500 text-md"},[_vm._v(" Email ")]),_c('neo-input',{staticClass:"rounded-lg h-10 w-full lg:w-2/6",attrs:{"disabled":"","placeholder":"Assigned Client","bg":"white"},model:{value:(_vm.userDetails.email),callback:function ($$v) {_vm.$set(_vm.userDetails, "email", $$v)},expression:"userDetails.email"}})],1),_c('div',{staticClass:"flex flex-col w-full lg:w-2/6 flex-1 xl:flex-row gap-4"},[_c('div',{staticClass:"flex flex-col gap-2"},[_c('span',{staticClass:"text-gray-500 text-md"},[_vm._v(" Assigned Roles ")]),_c('vue-multiselect',{staticClass:"h-14",attrs:{"multiple":true,"config":{ limit: 1 },"placeholder":"Select a Roles to add","show-labels":false,"maxHeight":200,"options":_vm.roleOptions,"track-by":"id","label":"title","loading":_vm.loading,"colors":{
                                    text: '#282828',
                                    bg: 'white',
                                    svg: '#282828',
                                    border: '#e8e8e8',
                                },"value":_vm.roleValue},model:{value:(_vm.roleValue),callback:function ($$v) {_vm.roleValue=$$v},expression:"roleValue"}})],1),_c('div',{staticClass:"flex flex-col flex-1 gap-2"},[_c('span',{staticClass:"text-gray-500 text-md"},[_vm._v(" Assigned Groups ")]),_c('vue-multiselect',{staticClass:"h-14",attrs:{"multiple":true,"config":{ limit: 1 },"placeholder":"Select a Groups to add","show-labels":false,"options":_vm.groupOptions,"track-by":"id","label":"title","loading":_vm.loading,"colors":{
                                    text: '#282828',
                                    bg: 'white',
                                    svg: '#282828',
                                    border: '#e8e8e8',
                                },"value":_vm.groupValue},model:{value:(_vm.groupValue),callback:function ($$v) {_vm.groupValue=$$v},expression:"groupValue"}})],1)])])]),_c('div',{staticClass:"mt-4 flex justify-end max-w-full gap-4"},[_c('neo-button',{staticClass:"text-white-text",attrs:{"text":"Delete User","icon":"trash","type":"error"},on:{"click":_vm.deleteUser}}),_c('neo-button',{staticClass:"text-white-text",attrs:{"text":"Save","icon":"check","type":"primary"},on:{"click":_vm.updateUser}})],1)])]),_c('modal-confirm',{ref:"confirm-popup",attrs:{"title":"Are you sure?","message":"Please confirm you're about to delete the field."}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }